import React, { useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import styled from 'styled-components'
import { Layout, Menu, Select, Drawer, Button, Radio, Tag, Tooltip } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Web3Status from '../../components/Web3Status'
import { useActiveWeb3React } from '../../hooks'
import { useWindowSize } from '../../hooks/useWindowSize'
// import ReedemModal from './ReedemModal';


import './index.less';
import {ReactComponent as  MarketsIcon } from '../../assets/svg/sts/markets.svg'
import {ReactComponent as  IdoIcon } from '../../assets/svg/sts/ido.svg'
import {ReactComponent as  FarmIcon } from '../../assets/svg/sts/farm.svg'
import DexIcon from '../../assets/svg/sts/dex.svg'
import WalletIcon from '../../assets/svg/sts/wallet.svg'
import CrossIcon from '../../assets/svg/sts/cross.svg'
import SettingsIcon from '../../assets/svg/sts/settings.svg'
import {ReactComponent as TwitterLogo} from '../../assets/svg/twitter.svg'
import {ReactComponent as MediumLogo} from '../../assets/svg/medium.svg'
import {ReactComponent as TelegramLogo} from '../../assets/svg/telegram.svg'
import {ReactComponent as WikiLogo } from '../../assets/svg/wikipedia-w.svg'
import { useTranslation } from 'react-i18next';
import FaucetModal from './AccountFaucetModal'
import STSBuy from './STSBuy'
import i18n from 'i18next';
import RAILogo from "../../assets/images/logo-dark.png"
import { setupEthRinkebyTestNetwork } from '../../utils'
import { ChainId } from '@uniswap/sdk';


const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;


const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  line-height: 64px;
  height: 64px;
  padding-left: 250px;
  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }
  .ant-btn{
    height: 40px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};

  #connect-wallet{
      border-color: #5542F6;
      height: 40px;
      margin-left: 10px;
      p{
        color: #000;
        height: 40px;
        line-height: 40px;
    }
  }
  #web3-status-connected{
      /* border-color: #5542F6; */
      span{
          color: #000;
          margin-left: 5px;
      }
  }
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)}; */

  background: transparent;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

const SideBarFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &>div{
    margin: 10px 0;
    }

`
const StyledLink = styled.a`
  svg{
      margin: 0 10px;
  }
`

const STSMenu = styled.ul<{ isSTS: boolean }>`
    display: flex;
    list-style: none;
    height: 64px;
    margin-bottom: 0;
    visibility: ${ props => props.isSTS ? "visible" : "hidden" };
    li {
        margin: 0 15px;
        color: #5542F6;
        font-weight: bold;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        margin-top: 66px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        li{
            margin:0 15px 0 0;
        }
    }  
`
const Logo = styled.img`
    width: 100px;
    margin-left: 20px;
`
const MarketsImg = styled.img`
    width: 18px;
`
const WikiLink = styled.a`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000000;
    svg {
        width: 18px;
        margin-right: 8px;
    }
`

const HeaderNotification = styled.div`
    width: 100%;
    padding: 10px 0;
    background-color: #5542F6;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 16px;
    text-align: center;
    line-height: 16px;
`
const HeaderContent = styled.div`
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const SwitchNetwork = styled.div`
    margin-right: 20px;
    min-width: 200px;
    display: flex;
    align-items: center;
    .ant-radio-group {
        display: flex;
    }
`

const SwitchItem = styled.div`
    padding: 0 10px;
`

interface STSProps {
    hideSideMenu?:boolean;
}
const STS: React.FC<STSProps> = ({ children, hideSideMenu }) =>  {
    const { t } = useTranslation();
    const { account, chainId } = useActiveWeb3React()
    const [networkValue, setNetworkValue] = useState(chainId)
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [isShowFaucetModal, setIsShowFaucetModal] = useState(false)
    // const [isShowReedemModal, setIsShowReedemModal] = useState(false)
    const {pathname} = useLocation();
    const { width } = useWindowSize()
    let selectedKey: string = pathname;
    if(pathname === '/' || pathname.includes('sts')){
        selectedKey = '/sts'
    }
    if(pathname.includes('ido')){
        selectedKey = '/ido'
    }

    const onCloseCollapse = () => {
        setCollapsed(false);
    };

    const toggle = () => {
        setCollapsed(!collapsed)
    };

    const handleChange = () => {

    }
    const changeLocale = (selectedLanguage: string) => {
        i18n.changeLanguage(selectedLanguage);
    }
    const isSTS = false;
    useEffect(() => {
        const ethereum = window.ethereum
        if (ethereum && ethereum.on) {
            ethereum.on('chainChanged', (_chainId: any) => window.location.reload());
            ethereum.on('disconnect', (_err: any) => window.location.reload());
            ethereum.on('accountsChanged', (_err: any) => window.location.reload());
        }
    }, [])

    useEffect(() => {
        document.addEventListener("visibilitychange", function() {
            if (document.visibilityState === 'visible') {
                // if (chainId !== 4 && chainId !== 80001) {
                    setupEthRinkebyTestNetwork(ChainId.MAINNET)
                // }
            }
        });
        if (document.visibilityState === 'visible') {
            // if (chainId !== 4 && chainId !== 80001) {
                setupEthRinkebyTestNetwork(ChainId.MAINNET)
            // }
        }
    }, [])
    useEffect(() => {
        if (i18n.language.indexOf("en") > -1) {
            changeLocale("en")
        }
    }, [])
    return (
        <Layout style={{width: '100%', minHeight: '750px'}}>
            <Header className="header" style={{background: '#fff', position: 'fixed', top: 0, width: '100%', zIndex: 1000}}>
                {!hideSideMenu &&
                    <HeaderNotification>
                        RAI Finance Beta Pro now live on Rinkeby Test Network!
                    </HeaderNotification>
                }
                <HeaderContent>
                    <div className="triger">{collapsed ? <MenuUnfoldOutlined style={{ fontSize: '20px'}} onClick={toggle}/> : <MenuFoldOutlined style={{ fontSize: '20px'}} onClick={toggle}/>}</div>
                    <Logo src={RAILogo} />
                    {width && width >= 768 &&
                        <STSMenu isSTS={isSTS}>
                            <li>
                                <Link to="/">
                                    {t("markets")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/sts/myportfolio">
                                    {t("account")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/sts/create">
                                    {t("create")}
                                </Link>
                            </li>
                            {account && chainId === 4 ? (
                                <li onClick={() => setIsShowFaucetModal(true)} >
                                    <Button type="primary"  >{t("get_test_token")}</Button>
                                </li>
                            ) : ""}
                        </STSMenu>
                    }
                    <HeaderElement>
                        {!hideSideMenu &&
                        <SwitchNetwork>
                            <Radio.Group buttonStyle="solid"
                            onChange={(e) => {
                                setNetworkValue(e.target.value)
                                console.log("network value", e.target.value)
                                setupEthRinkebyTestNetwork(e.target.value)
                            }}
                            value={networkValue}
                            >
                                <Radio.Button value={80001}>Mumbai</Radio.Button>
                                <Radio.Button value={4} >Rinkeby</Radio.Button>
                            </Radio.Group>
                        </SwitchNetwork>
                        }
                        <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
                            <Web3Status />
                        </AccountElement>
                    </HeaderElement>
                </HeaderContent>
            </Header>
            {width && width >= 768  && !hideSideMenu && 
                <Sider 
                    breakpoint="md"
                    width={250}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        paddingTop: '100px',
                        minHeight: '700px'
                    }}
                    collapsedWidth="0"
                    className="sidebar"
                >
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[selectedKey]}
                        defaultOpenKeys={['sub1', 'sub2', 'sub3']}
                        style={{ height: '100%', borderRight: 0 }}
                        expandIcon={<></>}
                        className="menu"
                    >
                        <SubMenu key="sub1" title="Social Trading">
                            <Menu.Item key="/sts" icon={
                                <MarketsIcon fill={`#5542F6`} />}><Link to="/">STS</Link></Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" title="Beta 3.0 Feature">
                            <Menu.Item key="/ido" disabled={chainId === 80001} className={`${chainId === 80001 ? 'polygon' : ''}`} icon={<IdoIcon fill={`${chainId === 80001 ? '#84818A' : '#5542F6'}`}/>}><Link to="/ido">IDO</Link>{chainId === 80001 && <Tag color="processing">Coming Soon</Tag>}</Menu.Item>
                            <Menu.Item key="/farm" disabled={chainId === 80001} className={`${chainId === 80001 ? 'polygon' : ''}`} icon={<FarmIcon fill={`${chainId === 80001 ? '#84818A' : '#5542F6'}`}/>}><Link to="/farm">Farm</Link>{chainId === 80001 && <Tag color="processing">Coming Soon</Tag>}</Menu.Item>
                            <Menu.Item key="/aggregateSwap" icon={<img src={DexIcon} />}><Link to="/aggregateSwap">Aggregator</Link></Menu.Item>
                            <Menu.Item key="/wallet" icon={<img src={WalletIcon} />}><Link to="/wallet">My Wallet</Link></Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub3" title="Coming Soon">
                            <Menu.Item key="7" icon={<img src={CrossIcon} />}>Cross-chain Swap</Menu.Item>
                        </SubMenu>
                    </Menu>
                    <SideBarFooter>
                        {/* <div><img src={SettingsIcon}/>&nbsp;&nbsp;Settings</div> */}
                        <div>
                            <Select defaultValue={i18n.language.indexOf("en") > -1 ? "en" : "ko"} style={{ width: 120 }} onChange={changeLocale}>
                                <Option value="en">English</Option>
                                <Option value="ko">한국어</Option>
                            </Select>
                        </div>
                        <div>
                            <StyledLink 
                                target='_blank'
                                href="https://twitter.com/RaiFinance">
                                    <TwitterLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink 
                                target='_blank'
                                href="https://medium.com/rai-finance">
                                    <MediumLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink 
                                target='_blank'
                                href="https://t.me/RaiFinance">
                                <TelegramLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink
                                target='_blank'
                                href="https://rai-finance.gitbook.io/rai-finance/" >

                            </StyledLink>
                        </div>
                        <WikiLink target="_blank" href="https://rai-finance.gitbook.io/rai-finance/" >
                          <WikiLogo width="20" height="20" fill="#5542F6"/> RAI Finance Wiki
                        </WikiLink>
                    </SideBarFooter>
                </Sider>
            }
            <Layout>
                <Content className={`${hideSideMenu ? 'hideSide' : ''}`}>
                    {/* {width && width < 768 && 
                        <STSMenu isSTS={isSTS}>
                            <li>
                                <Link to="/">
                                    {t("markets")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/sts/myportfolio">
                                    {t("account")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/sts/create">
                                    {t("create")}
                                </Link>
                            </li>
                            {account ? (
                                <li onClick={() => setIsShowFaucetModal(true)} >
                                    <Button type="primary" disabled>{t("get_test_token")}</Button>
                                </li>
                            ) : ""}
                            <li onClick={() => setIsShowReedemModal(true)}>
                                <Button type="primary">Reedem</Button>
                            </li>
                        </STSMenu>
                    } */}
                    {children}
                </Content>
            </Layout>
            <Drawer
                title=""
                placement="left"
                width={250}
                closable={false}
                onClose={onCloseCollapse}
                visible={collapsed}
                bodyStyle={{padding: 0}}
            >
                <Sider 
                    width={250}  
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        minHeight: '700px'
                    }}
                    collapsedWidth="0"
                >
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[pathname]}
                        defaultOpenKeys={['sub1', 'sub2', 'sub3']}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                        <SubMenu key="sub1" title="Social Trading">
                            <Menu.Item key="/sts" disabled={chainId === 80001} className={`${chainId === 80001 ? 'polygon' : ''}`} icon={
                                <MarketsIcon fill={`${chainId === 80001 ? '#84818A' : '#5542F6'}`} />}><Link to="/">STS</Link>{chainId === 80001 && <Tag color="processing">Coming Soon</Tag>}</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" title="Beta 3.0 Feature">
                            <Menu.Item key="/ido" disabled={chainId === 80001} className={`${chainId === 80001 ? 'polygon' : ''}`} icon={<IdoIcon fill={`${chainId === 80001 ? '#84818A' : '#5542F6'}`}/>}><Link to="/ido">IDO</Link>{chainId === 80001 && <Tag color="processing">Coming Soon</Tag>}</Menu.Item>
                            <Menu.Item key="/farm" disabled={chainId === 80001} className={`${chainId === 80001 ? 'polygon' : ''}`} icon={<FarmIcon fill={`${chainId === 80001 ? '#84818A' : '#5542F6'}`}/>}><Link to="/farm">Farm</Link>{chainId === 80001 && <Tag color="processing">Coming Soon</Tag>}</Menu.Item>
                            <Menu.Item key="/aggregateSwap" icon={<img src={DexIcon} />}><Link to="/dex">DEX</Link></Menu.Item>
                            <Menu.Item key="/wallet" icon={<img src={WalletIcon} />}><Link to="/wallet">My Wallet</Link></Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub3" title="Coming Soon">
                            <Menu.Item key="7" icon={<img src={CrossIcon} />}>Cross-chain Swap</Menu.Item>
                        </SubMenu>
                    </Menu>
                    <SideBarFooter>
                        <div><img src={SettingsIcon}/>&nbsp;&nbsp;Settings</div>
                        <div>
                        <Select defaultValue={localStorage.getItem("rai_lang") || "en"} style={{ width: 120 }} onChange={changeLocale}>
                            <Option value="en">English</Option>
                            <Option value="ko">한국어</Option>
                        </Select>
                        </div>
                        <div>
                            <StyledLink 
                                target='_blank'
                                href="https://twitter.com/RaiFinance">
                                    <TwitterLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink 
                                target='_blank'
                                href="https://medium.com/rai-finance">
                                    <MediumLogo fill="#5542F6"/>
                            </StyledLink>
                            <StyledLink 
                                target='_blank'
                                href="https://t.me/RaiFinance">
                                <TelegramLogo fill="#5542F6"/>
                            </StyledLink>
                        </div>
                    </SideBarFooter>
                </Sider>
            </Drawer>
            <FaucetModal
              isOpen={isShowFaucetModal}
              handleCancel={() => {setIsShowFaucetModal(false)}}
            />
            {/* <ReedemModal 
                isOpen={isShowReedemModal}
                handleCancel={() => {setIsShowReedemModal(false)}}
            /> */}
        </Layout> 
    )
}

export default STS;
