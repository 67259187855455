import React, {useState} from 'react'
import styled from 'styled-components'
import { ethers, BigNumber } from 'ethers';
import { Input, Tabs } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { ChainId, Currency, JSBI, TokenAmount } from '@uniswap/sdk'
import { TransactionResponse } from '@ethersproject/providers'
import { CheckCircle } from 'react-feather'
import { useActiveWeb3React } from '../../hooks'
import { TRAI, TSOFI } from '../../constants'
import { useCurrencyBalance } from 'state/wallet/hooks'
import { useModalOpen, useIdoModalToggle } from 'state/application/hooks'
import { ButtonPrimary, ButtonLight } from 'components/Button'
import { RowBetween, RowFixed } from 'components/Row'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'
import { configIdoList } from '../../config'
import { useClaimContract, useReedemContract } from 'hooks/useContract'
import { useApproveCallback, ApprovalState } from 'hooks/useApproveCallback'
import { useTransactionAdder } from 'state/transactions/hooks'
import STS from '../STS'
import { useClaimBSCSofi } from './hooks';
import { ReactComponent as Close } from 'assets/images/x.svg'
import { ReactComponent as BSCIcon } from 'assets/svg/bsc-logo.svg'
import ETHIcon from 'assets/images/ethereum-logo.png'
import MetaMaskLogo from 'assets/images/metamask.png'
import numeral from 'numeral';

const { TabPane } = Tabs;

const Wraper = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  background: #fff;
  @media (max-width: 768px) {
      width: 100%;
  }
  .ant-tabs{
    .ant-tabs-tab-btn{
      line-height: 30px;
      display: flex;
      align-items: center;
    }
    img{
      width: 30px;
      margin-right: 10px;
    }
    svg{
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
`
const ModalContent = styled.div`
  padding: 30px;
`
const ModalHeader = styled.div`
  position: relative;
  height: 85px;
  padding: 20px 30px 28px 30px;
  background-color: ${({ theme }) => theme.bg6};
`
const HeaderTitle = styled.div`
  color: #FFFFFF;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
`
const CloseColor = styled(Close)`
  color: #FFFFFF;
`
const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  right: 30px;
  transform: translate(0, -50%);
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const InputWraper = styled.div`
  position: relative;
  .ant-input[disabled]{
      background-color: #fff;
      color: #000;
  }
  .ant-input-group-addon{
    background: #fff;
  }
`
const ItemWraper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
const InputItem = styled.span`
  font-size: 16px;
  color: #000000;
`
const BalanceWraper = styled.div`
  color: #000;
  font-size:16px;
  font-weight: bold;
`
const SwapWraper = styled.div`
  color: #99AA99;
  font-size:16px;
`
const SVGWraper = styled.div`
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
svg {
  color: #0062DF;
  width: 16px;
}
`
const ButtonWraper = styled.div`
  min-height: 67px;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`
const Address = styled.div`
  background: #F8F8F8;
  padding: 14px;
  color: #9A9A9A;
  p{
    margin-bottom: 0;
  }
  a{
    color: #000;
    font-size: 14px;
    word-break: break-all;
  }
`

const ReedemSOFI = () => {
    const contractAddress = '0xc7abb2cea257b7c9458a56d13f98a4bc537ac41a';
    const { chainId, account, library } = useActiveWeb3React()
    const [approval, approveCallback] = useApproveCallback(new TokenAmount(TRAI[chainId ?? 1], JSBI.BigInt(100000000000000000000)), contractAddress)
    const userBalance = useCurrencyBalance(account ?? undefined, TRAI[chainId ?? 1])
    const contract = useReedemContract(contractAddress)
    const claimContract = useClaimContract('0xDF0D8d39cD3914260fF8658A11cB1D072303b9AC')
    const addTransaction = useTransactionAdder()
    const { addToken, success } = useAddTokenToMetamask(TSOFI[chainId ?? 1])
    const claimBscInfo = useClaimBSCSofi(account, chainId);
    const [activeTab, setActiveTab] = useState<string>('eth')
    const { t } = useTranslation()

    async function onReedem() {
      if (contract && userBalance?.toSignificant(18).toString()) {
        console.log("contract", contract)
        if (approval !== ApprovalState.APPROVED) {
          console.log("not approve");
          approveCallback()
        } else {
          await contract
            .swap(ethers.utils.parseUnits(userBalance?.toSignificant(18).toString(), 18))
            .then((response: TransactionResponse) => {
              console.log("response", response)
              addTransaction(response, {
                summary: `Convert RAI to SOFI`
              })
            })
            .catch((error: any) => {
              alert(error.message)
              console.log(error)
            })
        }
      }
    }

    async function onClaim() {
      if (claimContract && claimBscInfo.amount) {
        await claimContract
          .claim(BigNumber.from(claimBscInfo.amount), claimBscInfo.signature)
          .then((response: TransactionResponse) => {
            console.log("response", response)
            addTransaction(response, {
              summary: `Claim SOFI`
            })
          })
          .catch((error: any) => {
            alert(error.message)
            console.log(error)
          })
      }
    }

    const handleTabChange = (activeKey: string) => {
      setActiveTab(activeKey)
    }
    
    return (
        <STS hideSideMenu={true}>
         {/* <Modal visible={isOpen} footer={null} width={430} bodyStyle={{padding: 0}} closable={false}> */}
            <Wraper>
                <ModalHeader>
                  <HeaderTitle>Convert to SOFI</HeaderTitle>
                </ModalHeader>
                <Tabs activeKey={activeTab} centered onChange={handleTabChange}>
                  <TabPane tab={<><img src={ETHIcon}/>ETH</>} key="eth">
                    <ModalContent>
                      <InputWraper>
                        <ItemWraper>
                          <InputItem>{t("from")}</InputItem>
                          <BalanceWraper>{t('avaliable_balance')} &nbsp;
                            {Number(userBalance?.toSignificant(18)).toLocaleString("en")}
                            RAI</BalanceWraper>
                        </ItemWraper>
                        {/* <StyleMaxButton onClick={() => {
                          onUserInput(userBalance?.toSignificant(4) || "")
                        }}>{t("max")}</StyleMaxButton> */}
                        <Input
                          size={"large"}
                          addonAfter={"RAI"}
                          value={Number(userBalance?.toSignificant(18)).toLocaleString("en")}
                          disabled
                          // onChange={(e) => {
                          //   onUserInput(e.target.value)
                          //   // setToInputValue(String(ratio * Number(value)))
                          // }}
                        />
                      </InputWraper>
                      <SVGWraper>
                        <ArrowDownOutlined/>
                      </SVGWraper>
                      <InputWraper>
                        <ItemWraper>
                          <InputItem>
                            {t("to")}
                          </InputItem>
                          <SwapWraper>1 RAI = 1 SOFI</SwapWraper>
                        </ItemWraper>
                        <Input
                          size={"large"}
                          addonAfter={"SOFI"}
                          value={Number(userBalance?.toSignificant(18)).toLocaleString("en")}
                          disabled
                          // onChange={(e) => {
                          //   setToInputValue(e.target.value)
                          // }}
                        />
                      </InputWraper>
                      <ButtonWraper>
                      {approval !== ApprovalState.APPROVED ? (
                        <ButtonPrimary
                          onClick={() => approveCallback()}
                          disabled={
                            approval === ApprovalState.PENDING ||
                            approval === ApprovalState.UNKNOWN
                          }
                        >Approve</ButtonPrimary>
                      ) : (
                        <ButtonPrimary
                        onClick={() => {
                          onReedem()
                        }}
                      >Swap</ButtonPrimary>
                      )}
                      </ButtonWraper>
                      <Address><p>Contract Address: </p><a href="https://etherscan.io/token/0xB49fa25978abf9a248b8212Ab4b87277682301c0">0xB49fa25978abf9a248b8212Ab4b87277682301c0</a></Address>
                      {library?.provider?.isMetaMask && (
                        <ButtonLight mt="12px" padding="6px 12px" width="fit-content" onClick={addToken}>
                          {!success ? (
                            <RowFixed>
                              Add SOFI to Metamask <StyledLogo src={MetaMaskLogo} />
                            </RowFixed>
                          ) : (
                            <RowFixed>
                              Added SOFI{' '}
                              <CheckCircle size={'16px'} stroke={'#27AE60'} style={{ marginLeft: '6px' }} />
                            </RowFixed>
                          )}
                        </ButtonLight>
                      )}
                    </ModalContent>
                  </TabPane>
                  <TabPane tab={<><BSCIcon/>BSC</>} key="bsc">
                    <ModalContent>
                      <InputWraper>
                        <ItemWraper>
                          <InputItem>{t("from")}</InputItem>
                          <BalanceWraper>{t('avaliable_balance')} &nbsp;
                            {numeral(claimBscInfo.amount / 10 ** 18).format("0,00.00")}
                            RAI</BalanceWraper>
                        </ItemWraper>
                        {/* <StyleMaxButton onClick={() => {
                          onUserInput(userBalance?.toSignificant(4) || "")
                        }}>{t("max")}</StyleMaxButton> */}
                        <Input
                          size={"large"}
                          addonAfter={"RAI"}
                          value={numeral(claimBscInfo.amount / 10 ** 18).format("0,00.00")}
                          disabled
                          // onChange={(e) => {
                          //   onUserInput(e.target.value)
                          //   // setToInputValue(String(ratio * Number(value)))
                          // }}
                        />
                      </InputWraper>
                      <SVGWraper>
                        <ArrowDownOutlined/>
                      </SVGWraper>
                      <InputWraper>
                        <ItemWraper>
                          <InputItem>
                            {t("to")}
                          </InputItem>
                          <SwapWraper>1 RAI = 1 SOFI</SwapWraper>
                        </ItemWraper>
                        <Input
                          size={"large"}
                          addonAfter={"SOFI"}
                          value={numeral(claimBscInfo.amount / 10 ** 18).format("0,00.00")}
                          disabled
                          // onChange={(e) => {
                          //   setToInputValue(e.target.value)
                          // }}
                        />
                      </InputWraper>
                      <ButtonWraper>
                      <ButtonPrimary
                        onClick={() => {
                          onClaim()
                        }}
                      >Claim</ButtonPrimary>
                      </ButtonWraper>
                      <Address><p>Contract Address: </p><a href="https://etherscan.io/token/0xB49fa25978abf9a248b8212Ab4b87277682301c0">0xB49fa25978abf9a248b8212Ab4b87277682301c0</a></Address>
                      {library?.provider?.isMetaMask && (
                        <ButtonLight mt="12px" padding="6px 12px" width="fit-content" onClick={addToken}>
                          {!success ? (
                            <RowFixed>
                              Add SOFI to Metamask <StyledLogo src={MetaMaskLogo} />
                            </RowFixed>
                          ) : (
                            <RowFixed>
                              Added SOFI{' '}
                              <CheckCircle size={'16px'} stroke={'#27AE60'} style={{ marginLeft: '6px' }} />
                            </RowFixed>
                          )}
                        </ButtonLight>
                      )}
                    </ModalContent>
                  </TabPane>
                </Tabs>
              </Wraper> 
         {/* </Modal> */}
        </STS>
    ) 
}

export default ReedemSOFI;