import { useEffect, useState, useCallback } from 'react';
import { ChainId } from '@uniswap/sdk'
import { APILIST } from '../../constants'
import { get } from '../../utils/request';

export const useClaimBSCSofi = (address: string | undefined | null, chainId: ChainId | undefined) => {
    const [claimBSCInfo, setClaimBSCInfo] = useState<any>({})
    const fetchClaimBSCInfo = useCallback(() => {
        const api_url = 'https://prodapi.rai.finance';
        get(`${api_url}/bsc/sofi/claimbsc?address=${address}`).then(async (res: any) => {
          if(res){
            setClaimBSCInfo(res)
          }
        })
    },[])
    useEffect(() => {
        if(address){
            fetchClaimBSCInfo()
        }
    }, [address])
    return claimBSCInfo;
}