import React, { Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
// import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import Wallet from './Wallet'
import Farm from './Farm'
import Liquidity from './Liquidity'
import Markets from './STS/Markets'
import CreatePortfolio from './STS/CreatePortfolio'
import Portfolio from './STS/Portfolio'
import Manager from './STS/Manager'
import STSBuy from './STS/STSBuy'
import MyPortfolio from './STS/MyPortfolio'
import Home from './Home'

import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
} from './AddLiquidity/redirects'
import Earn from './Earn'
import Manage from './Earn/Manage'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap/aggregate'
import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Ido from './Ido'
import IdoHistory from './Ido/idoHistory'
import IdoDetail from './Ido/IdoDetail'
import ReedemSOFI from './ReedemSOFI'
import {ReactComponent as TwitterLogo} from '../assets/svg/twitter.svg'
import {ReactComponent as MediumLogo} from '../assets/svg/medium.svg'
import {ReactComponent as TelegramLogo} from '../assets/svg/telegram.svg'
import {ReactComponent as GithubLogo} from '../assets/svg/github.svg'

import './App.less';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  min-height: 100vh;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div<{ isSTS: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  ${({ isSTS , theme}) =>
  !isSTS && theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 2rem;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 0;
`
const Footer = styled.div`
  width: 100%;
  height: 128px;
  padding: 56px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg6};
  svg {
    margin: 0 10px;
  }
`

const StyledLink = styled.a`

`
function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export default function App() {

  const {pathname} = useLocation();
  console.log('location: ', pathname);
  const isSTS = true
  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        {/* <URLWarning /> */}
        {!isSTS &&         
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
        }
        <BodyWrapper isSTS={isSTS}>
          <Popups />
          <Polling />
          <TopLevelModals />
          <Web3ReactManager>
            <Switch>
              {/* <Route exact strict path="/dex" component={Swap} /> */}
              <Route exact strict path="/" component={ReedemSOFI} />
              {/* <Route exact strict path="/aggregateSwap" component={Swap} />
              <Route exact strict path="/liquidity/:currencyA/:currencyB" component={Liquidity} />
              <Route exact strict path="/wallet" component={Wallet} />
              <Route exact strict path="/farm" component={Farm} /> */}
              {/* <Route exact strict path="/" component={Swap} /> */}
              {/* <Route exact strict path="/home" component={Home} /> */}
              {/* <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/ido" component={Ido} />
              <Route exact strict path="/ido/:contractAddress" component={IdoDetail} />
              <Route exact strict path="/idoHistory/:userAddress" component={IdoHistory} />
              <Route exact strict path="/" component={Markets} />
              <Route exact strict path="/sts/myportfolio" component={MyPortfolio} />
              <Route exact strict path="/sts/create" component={CreatePortfolio} />
              <Route exact strict path="/sts/portfolio/:address" component={Portfolio} />
              <Route exact strict path="/sts/buy/:contractAddress" component={STSBuy} />
              <Route exact strict path="/sts/sell/:contractAddress" component={STSBuy} />
              <Route exact strict path="/sts/manage/:contractAddress" component={STSBuy} />
              <Route exact strict path="/sts/manager/:address" component={Manager} />
              <Route component={RedirectPathToSwapOnly} /> */}
            </Switch>
          </Web3ReactManager>
          <Marginer />
        </BodyWrapper>
        {!isSTS &&         
          <Footer>
              <StyledLink 
                  target='_blank'
                  href="https://twitter.com/RaiFinance">
                    <TwitterLogo fill="#fff"/>
              </StyledLink>
              <StyledLink 
                  target='_blank'
                  href="https://github.com/RaiFinance">
                      <GithubLogo fill="#fff"/>
              </StyledLink>
              <StyledLink 
                  target='_blank'
                  href="https://medium.com/rai-finance">
                      <MediumLogo fill="#fff"/>
              </StyledLink>
              <StyledLink 
                  target='_blank'
                  href="https://t.me/RaiFinance">
                  <TelegramLogo fill="#fff"/>
              </StyledLink>
          </Footer>
        }
      </AppWrapper>
    </Suspense>
  )
}
